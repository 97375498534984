/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Header Styles */
header {
  background-color: #333;
  color: #fff;
  padding: 1rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-right: 1rem;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.auth-buttons button {
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #fff;
  color: #333;
  border: none;
  cursor: pointer;
}

.register {
  background-color: #ff8c00;
  color: #fff;
}

/* Hero Section Styles */
.hero {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #f5f5f5;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.buttons button,
.buttons a {
  margin: 0 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

.explore {
  background-color: #ff8c00;
  color: #fff;
}

.learn-more {
  background-color: #fff;
  color: #333;
  text-decoration: none;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 1rem;
  text-align: center;
}

.login-container {
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 30%;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-label {
  font-weight: 600;
  color: #333;
}

.form-control {
  border: 1px solid #ccc;
}

.form-control:focus {
  border-color: #6c757d;
  box-shadow: none;
}

.or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.or-divider hr {
  width: 40%;
  border-top: 1px solid #ccc;
}

.or-divider p {
  margin: 0 10px;
  color: #666;
}

@media (max-width: 768px) {
  .login-form {
    width: 80%;
  }
}
.error{
  color:red;
  font-size: 15px;
}

/*css for library.jsx

/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header styles */
header {
  background-color: #333;
  color: white;
  padding: 10px 0;
}

header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header nav .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

header nav ul {
  display: flex;
  list-style-type: none;
}

header nav ul li {
  margin-right: 20px;
}

header nav ul li a {
  color: white;
  text-decoration: none;
}

header nav .auth-buttons {
  display: flex;
  align-items: center;
}

header nav .auth-buttons .button {
  margin-left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

header nav .auth-buttons .button:hover {
  background-color: #0056b3;
}

/* Hero section styles */
.hero {
  text-align: center;
  padding: 80px 20px;
  background-color: #f0f0f0;
}

.hero h1 {
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.hero p {
  font-size: 1.2rem;
  color: #666;
}

.hero .buttons {
  margin-top: 20px;
}

.hero .buttons .explore {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 4px;
}

.hero .buttons .explore:hover {
  background-color: orange;
}

.hero .buttons .learn-more {
  margin-left: 10px;
  color: #007bff;
  text-decoration: none;
}

.hero .buttons .learn-more:hover {
  text-decoration: underline;
}

/* Shift cards section styles */
.shift-cards {
  padding: 40px 20px;
  text-align: center;
}

.shift-cards h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card p {
  color: #666;
}
.nav{
  color:yellow
}